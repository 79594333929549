import styled from 'styled-components';

export const VideBox = styled.div
`
 video{
     object-fit:fill;
     margin: 0 auto
 }

`

