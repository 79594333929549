
import { Flex } from '@chakra-ui/react'
import React, { Component } from 'react'
// import ReactPlayer from 'react-player'
import ReactPlayer from 'react-player/lazy'

import {VideBox} from './minPlayerercss'

const ResponsivePlayer = ({url,controls,playing,isPhone,isCenterVideo}) => {
      return (
        !isCenterVideo ?
        <VideBox className='player-wrapper'style={{
          height:'100%',
          width:'100%',
        }}>
          <ReactPlayer
            className='react-player'
            url={url}
            // 循环播放
            loop={true}
            // width='100%'
            // height='100%'
            width='auto'
            // height= 'flex'
            // height='auto'
            // height='10%'
            muted={true}
            // 进度条显示
            controls = {controls}
            playing = {playing}
            x5-playsinline={true}
            webkit-playsinline={true}
            playsinline={true}
            // light="https://t7.baidu.com/it/u=2537132411,3912460994&fm=193&f=GIF"
          />
        </VideBox>
        :
        <VideBox className='player-wrapper'style={{
          height:'100%',
          width:'100%',
        }}>
          <ReactPlayer
            className='react-player'
            url={url}
            // 循环播放
            loop={true}
            // width='100%'
            // height='100%'
            // width='auto'
            width= '100%'
            height='100%'
            // height= 'flex'
            // height='auto'
            // height='10%'
            muted={true}
            // 进度条显示
            controls = {controls}
            playing = {playing}
            x5-playsinline={true}
            webkit-playsinline={true}
            playsinline={true}
            // light="https://t7.baidu.com/it/u=2537132411,3912460994&fm=193&f=GIF"
          />
        </VideBox>
      )
  
  }

  export default ResponsivePlayer